import {
  non_adult_accounts,
  other_website_labels,
  active_sites,
} from "@/helpers/common";

// labels should use internationalization settings
export const labels = {
  en: {
    // Accounts > <Trainer Account> -> Model assignment tab
    assign_models: "Assign models to trainer",
    assign_models_note:
      "Changes will be applied next time the user logs into the application.",
    assign_models_default: "By default, trainer has no restrictions.",
    trainer_chat_to: "Trainer can chat only with their their allocated models",
    trainer_chat_from:
      "Allocated models can chat only with their their allocated trainers",
    trainer_email: "Trainer can send messages only to their allocated models",
    trainer_email_group:
      'Trainer can\'t send messages to the entire "Model" group',
    trainer_schedule_edit:
      "Trainer can edit schedule only for their allocated models",
    trainer_schedule_view:
      "Trainer can see the schedule only for their allocated models",
    trainer_see_bonuses:
      "Trainer can see all the bonuses allocated to their models",
    trainer_see_penalties:
      "Trainer can only see their allocated models penalties",
    trainer_quick_bonuses_note: "Quick bonuses are not shown by default",
    trainer_messaging_label: "Message sender",
    trainer_messaging:
      "Trainer can send messages only for their allocated models",
    trainer_remote_label: "Remote computer control (Lite manager, Radmin etc.)",
    trainer_remote: "Trainer can only access their allocated models PCs",
    trainer_monitoring_label: "Models monitoring",
    trainer_monitoring:
      "Trainer can only see their allocated models on the monitoring page",
    trainer_login_label: "Model STUMA login accounts",
    trainer_login: "Trainer can only see their allocated model accounts",
    trainer_training_section_label: "Training sections",
    trainer_training_section:
      "Trainer can only see or edit their allocated models training sections",

    // global labels
    delete: "Delete",
    no_entries: "No entries",
    write_message: "Write your message...",

    attachments: "Attachments",
    gallery: "Media Gallery",

    buttons: {
      edit: "Edit",
      delete: "Delete",
    },

    // Studio configuration
    application_modules: {
      heading: "Application Modules",
      trafic: "Traffic modules",
      mess: "Messenger",
      zopim: "Zopim",
      radmin: "Radmin",
      lite: "Lite Manager",
      rac: "RAC Manager",
      anydesk: "AnyDesk",
      vnc: "VNC",
      members_login: "Members Accouts",
      rulez: "Rules",
      encoder: "Encoder",
    },

    active_sites_heading: "Active Sites",
    active_sites,
    active_sites_non_adult: other_website_labels,
    // Studio Configuration Boost Traffic
    boost_sites: [
      { label: "MFC", value: "mfc" },
      { label: "Stripchat", value: "stripchat" },
      { label: "Chaturbate", value: "chaturbate" },
      { label: "Flirt4Free", value: "flirt4free" },
      { label: "LiveJasmin", value: "livejasmin" },
      { label: "Naked", value: "naked" },
      { label: "Cam4", value: "cam4" },
      { label: "CamSoda", value: "camsoda" },
      { label: "Bonga", value: "bonga" },
      { label: "Cams", value: "cams" },
    ],
    boost_display_butotn: "Display Boost Button on Model Page",
    boost_numerals: {
      first: "1st",
      second: "2nd",
      third: "3rd",
    },
    boost_plans: {
      guests: "Guest",
      free: "Free",
      paid: "Paid",
      premium: "Premium",
      premium_new: "Premium 250",
      premium_plus: "Premium Plus",
    },

    asm_traffic_access_help: {
      super: "Super users can control model boost",
      Manager: "Managers can control model boost",
      Trainer: "Trainers can control model boost",
      boost_ban:
        "Display boost control only if models have time boost selected",
    },

    // Website track
    form_preview: "Form Preview",
    form: {
      admin: "Admin",
      assign_models: "Assign models to trainer",
      assign_models_note:
        "Note: <br/>Changes will be applied next time the user logs into the application.<br/>",
      assign_models_default: "By default, trainer has no restrictions.",
      trainer_chat_to: "Trainer can chat only with their allocated models",
      trainer_chat_from:
        "Allocated models can chat only with their allocated trainers",
      trainer_email: "Trainer can send messages only to their allocated models",
      trainer_email_group:
        'Trainer can\'t send messages to the entire "Model" group',
      trainer_schedule:
        "Trainer can edit schedule only for their allocated models",
      trainer_schedule_set:
        "Trainer can see the schedule only for their allocated models",
      trainer_messaging:
        "Trainer can send messages only for their allocated models",
      trainer_remote_info:
        "Remote computer control (Lite manager, Radmin etc.)",
      trainer_remote: "Trainer can only access their allocated models PCs",
      trainer_monitoring:
        "Trainer can only see their allocated models on the monitoring page",
      trainer_login_info: "Model STUMA login accounts",
      trainer_login: "Trainer can only see their allocated model accounts",
      trainer_training:
        "Trainer can only see or edit their allocated models training sections",
      training_section: "Training sections",
      allocated_models: "Allocated models:",
      allocated_trainers: "Allocated trainers:",
      connected: "Connected",
      disconnected: "Disconnected",
      delete_all_data: "Delete all data",
      show_all_users: "Show all models",
      remove_from_chedule: "Remove user from schedule",
      select_all_days: "Select all days",
      show_incative: "Also show inactive users",
      view_message: "View message",
      show_offline: "Show offline sites",
      user_archive: "User archive",
      available: "Available",
      busy: "Busy",
      privacy: "Privacy",
      enter_send: "Enter to send",
      silent: "Silent mode",
      apear_to: "Apear online to: ",
      away_overwrite: "Override default away message: ",
      write_message: "Write your message...",
      search_contacts: "Search contacts...",
      user: "User",
      model: "Model",
      trainer: "Trainer",
      manager: "Manager",
      active_only: "Active users only",
      active_account: "Account is active",
      "2fa_active": "2FA is active",
      shelter: "Accommodations provided by studio",
      username: "Username",
      password: "Password",
      user_on_site: "Username on site",
      aff_link: "Affiliate link for",
      generate: "Generate",
      name: "Name",
      surname: "Surname",
      address: "Address",
      studio: "Studio",
      studios: "Studios",
      account_type: "Account type",
      phone: "Phone number",
      email: "Email",
      birthday: "Day of birth",
      birthmonth: "Month of birth",
      birthyear: "Year of birth",
      id_num: "ID number",
      ssn: "SSN",
      exp_month: "Expiring month",
      exp_year: "Expiring year",
      bank: "Bank account",
      zipcode: "Zipcode",
      city: "City",
      country: "Country",
      lockers: "Lockers",
      hired_since: "Hired since",
      hired_to: "Hired to",
      contract: "Contract number",
      ended: "Colaboration ended",
      coupleH: "Couple with",
      log_type: "Logs type",
      pshyco: "Psychological type",
      personality: "Personality",
      intelligence: "Intelligence",
      college: "College",
      perspectives: "Perspectives",
      physical_potential: "Physical potential",
      creative: "Creative",
      spoken: "Spoken languages",
      receptivity: "Receptivity",
      signs: "Distinct signs",
      description: "Description",
      short_description: "Short description based on psychological type",
      recomandations: "Recomandations for training",
      bonus_type: "Bonus type",
      selected_interval: "Display for selected interval",
      interval: "Interval",
      type: "Type",
      target: "Target",
      reward: "Reward",
      online: "Online",
      actions: "Actions",
      status: "Status",
      type_search: "Type to search",
      folder_name: "Folder name",
      select_folder: "Select folder",
      share_with: "Shared with",
      note_title: "Note title",
      note_content: "Note content",
      change_pass: "Change Your Password",
      two_fa: "Two Factor Authentication",
      old_pass: "Old password",
      new_pass: "New password",
      chat_setts: "Chat privacy settings",
      email_setts: "Email privacy settings",
      first_period: "First period",
      second_period: "Second period",
      period: "Period",
      assign_to: "Assign to",
      assigned_to: "Assigned to",
      upfront_payments: "Upfront payments",
      date_range: "Select date range (From - To)",
      age_range: "Select age range (From - To)",
      speaks: "Candidate speaks",
      xp: "Candidate experience",
      upfront_config: "Upfront configuration",
      active_sites: "Active sites",
      super_users: "Super users",
      local_studios: "Local studios",
      intervals: "Intervals",
      rooms: "Rooms",
      rooms_num: "Rooms number",
      shifts_num: "Number of shifts",
      first_shift: "First shift starts at",
      working_hours: "Working hours",
      brakes: "Brakes",
      break_time: "Brake time",
      form_labels: "Form labels",
      regulations: "Regulations",
      global_settings: "Global settings",
      use_backup: "Use backup IP's",
      entries: "Entries",
      online_required: "Online time is required / shift",
      minutes_shift: "Online minutes / shift",
      target_sum: "Target sum",
      shifts_required: "Required shifts",
      minutes_day: "Minutes / Day",
      target_type: "Target type",
      bonus_time: "Period of time",
      starting_date: "Starting date",
      ending_date: "Ending date",
      has_sum: "Has sum",
      select_users: "Select one or more users",
      user_logs: "All user logs",
      seek_logs: "Type of log you seek",
    },
    custom: {
      new: "New",
      more_new: "New",
      na: "Not available",
      choose_user: "Choose a username",
      choose_pass: "Choose a password",
      choose_type: "Select user account type",
      assign_studio: "Assign to a studio",
      provide_email: "Provide an email address",
      provide_details: "Provide account details",
      account_details: "Provide user personal informations",
      user_details: "STUMA user",
      user_info: "Personal informations",
      user_name: "Provide user name",
      user_surname: "Provide user surname",
      user_phone: "Provide a phone number",
      user_address: "Provide an address",
      address_details: "Address details",
      birth_date: "Birth date",
      provide_bank: "Provide user bank account",
      strict_email: "Please enter a valid email address",
      only_letters: "Letters and spaces only",
      id_card: "Government issued ID",
      pass_match: "Please enter the same password as above",
      strong_pass:
        "Your password must contain:<br><li>a minimum of 1 lower case letter [a-z];</li><li>a minimum of 1 upper case letter [A-Z];</li><li>a minimum of 1 numeric character [0-9];</li><li>a minimum of 1 special character: ~`!@#$%^&*()-_+={}[]|<>\\;:,./?</li><li>password must be at <b>least 7 characters</b> in length</li>",
      valid_number: "Please enter a valid number",
      show_pass: "Show password",
      confirm_account: "Confirm user account",
      profile: "Profile",
      step: "Step",
      of: "Of",
      owner: "Owner",
      owners: "Owners",
      add_new_owner: "Add a new owner",
      remove_owner: "Remove owner",
      member: "Member",
      connected: "Connected",
      away: "Away",
      silent: "Silent mode",
      enter_send: "Enter to send",
      view_only: "View only",
      file_transfer: "File transfer",
      full_control: "Full control",
      occupied: "Occupied",
      unoccupied: "Unoccupied",
      copy_text: "Copy text to clipboard",
      target_bonus: "Target bonus",
      bonus: "Bonus",
      progress: "Progress",
      updated_on: "Updated on",
      your_time: "Your time",
      required: "Required",
      reach_sum: "Sum to reach",
      cur_sum: "Current sum",
      have: "Have",
      requested_online: "Requested online",
      no_break: "No break",
      message_posted: "Last message was posted on",
      my_room: "My room",
      is_running: "Shift is running",
      text_format: "Define text format",
      select_post: "Select post",
      i_agree: "I agree",
      number_boxes: "Number of boxes",
      checkboxes_name: "Checkboxes group name",
      number_radios: "Number of radios",
      radios_name: "Radios group name",
      label_name: "Label name",
      section_name: "Section name",
      checkbox_group: "Checkbox group",
      button_name: "Botton name",
      tariner_bonus: {
        number: "Fixed",
        target_percent: "Target percent",
        sum_percent: "Sum percent",
      },
      income: "Income",
      accomplished: "Accomplished",
      divided: "Divided",
      show_reward: "Show reward",
      show_target: "Show target",
      achieved_only: "Achieved only",
      reward: "Reward",
      percent: "Percent",
      fixed: "Fix",
      bonus_type: "Bonus type",
      overwrite_global: "Override global",
      option: "Option",
      english: "English",
      experience: "Experience",
      english_level: "English level",
      label_comments: "Other comments",
      upload_text: "Upload text",
      submit_btn_text: "Submit button text",
      form_preview: "Form preview",
      domain_name: "Domain name",
      fa_code: "2FA Code",
      repeat: "Repeat",
      bj: "Blowjob skills",
      squirt: "Squirting",
      sloppy: "Sloppy blowjob",
      pov: "Point of view",
      anal: "Anal skills",
      dp: "Double penetration",
      curvy: "Curvy",
      slim: "Slim",
      milf: "MILF",
      bTits: "Big tits",
      bAss: "Big butt",
      hairy: "Hairy",
      tight: "Tight pussy",
      linistita: "Calm",
      maniaca: "Maniac",
      manipulatoare: "Manipulator",
      perseverenta: "Perseverance",
      capricioasa: "Whimsical",
      jucausa: "Playful",
      plangacioasa: "Crying",
      agasanta: "Annoying",
      echilibrata: "Balanced",
      lenesa: "Lazy",
      malleable: "Malleable",
      strong: "Strong",
      fade: "Fade",
      not_really: "Not really",
      sly: "Sly",
      yes: "Yes",
      no: "No",
      not_at_all: "Not at all",
      physical_potential: "Physical Potential",
      creative: "Creative",
      english_low: "English beginer",
      english_middle: "English intermediate",
      english_high: "English advanced",
      french_low: "French beginer",
      french_middle: "French intermediate",
      french_high: "French advanced",
      spanish_low: "Spanish beginer",
      spanish_middle: "Spanish intermediate",
      italian_low: "Italian beginer",
      italian_middle: "Italian intermediate",
      italian_high: "Italian advanced",
      spanish_high: "Spanish advanced",
      visible_scars: "Visible scars",
      prominent_moles: "Prominent moles",
      strabism: "Strabism",
      others: "Others",
      tattoos: "Tatuaje",
      languages: "Language skills",
      one_year: "One year",
      two_year: "Two year",
      three_year: "Three year",
      more_year: "More than year",
      one_week: "One week",
      two_week: "Two weeks",
      one_month: "One month",
      two_month: "Two months",
      three_month: "Three months",
      six_month: "Six months",
      forever: "Forever",
      to: "To",
      no_experience: "No experience",
    },
  },
};

export const helpText = {
  en: {
    application_modules: {
      heading: "Activate / Deactivate application modules",
      trafic: "Traffic modules visible and configurable in the management page",
      mess: "Sends messages to members that interact with the model",
      zopim: "Zopim chat",
      radmin: "Remote Administrator Messenger",
      lite: "Lite Manager Administrator Control",
      rac: "RAC Remote Administrator Control",
      anydesk: "Anydesk Remote Desktop",
      vnc: "VNC",
      members_login: "Manage members accounts for working sites",
      // "'Super users pot da acces managerilor pentru configurarile regulamentului"
      rulez:
        'Super users can allow managers to configure the studio rules",            ',
      encoder: "Users can access the encoder in the application",
    },
    active_sites_heading: "Websites enabled for the selected studio",
  },
};
