<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@media (min-width: 768px) {
  .md-block {
    display: block !important;
  }
  .md-none {
    display: none !important;
  }
  .md-btn-primary {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    margin-right: 0.5rem;
  }
  .md-btn-danger {
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
  }
  .md-primary-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    background-color: rgba(88, 103, 221, 0.1);
    color: #5867dd;
    margin-right: 0.5rem !important;
  }
  .md-primary-btn:hover {
    color: white;
    background-color: #5d78ff;
    border-color: #5d78ff;
  }
  .md-danger-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    background-color: rgba(253, 57, 122, 0.1);
    color: #fd397a;
  }
  .md-danger-btn:hover {
    color: white;
    background-color: #fd397a;
    border-color: #fd397a;
  }
}

@media (max-width: 767px) {
  .sm-dropdown-styles {
    position: absolute;
    will-change: transform;
    top: 0;
    left: 0;
    transform: translate3d(32px, 33px, 0);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link-text-danger {
  color: #dc4e41 !important;
}

.link-text-primary {
  color: #5d78ff !important;
}

.input-group > .twitter-typeahead {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .twitter-typeahead:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .twitter-typeahead > .tt-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-control.tt-input:focus {
  z-index: 3;
}
span.twitter-typeahead .tt-menu,
span.twitter-typeahead .tt-dropdown-menu {
  cursor: pointer;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
span.twitter-typeahead .tt-suggestion {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}
.input-group.input-group-lg span.twitter-typeahead .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.input-group.input-group-sm span.twitter-typeahead .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
span.twitter-typeahead {
  width: 100%;
}
.input-group span.twitter-typeahead {
  display: block !important;
  height: 34px;
}
.input-group span.twitter-typeahead .tt-menu,
.input-group span.twitter-typeahead .tt-dropdown-menu {
  top: 32px !important;
}
.input-group
  span.twitter-typeahead:not(:first-child):not(:last-child)
  .form-control {
  border-radius: 0;
}
.input-group span.twitter-typeahead:first-child .form-control {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group span.twitter-typeahead:last-child .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-group.input-group-sm span.twitter-typeahead {
  height: 30px;
}
.input-group.input-group-sm span.twitter-typeahead .tt-menu,
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
  top: 30px !important;
}
.input-group.input-group-lg span.twitter-typeahead {
  height: 46px;
}
.input-group.input-group-lg span.twitter-typeahead .tt-menu,
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
  top: 46px !important;
}
</style>
