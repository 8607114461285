import axios from 'axios'
function menuLanguage(url, lang, callback){
    axios.post(url+'menuLanguage', {lang})
    .then(function (res) {
        callback(null, res.data);
        }).catch(error=>{
        if (error.response) {
            callback(error.response.data);
        } else if (error.request) {
            callback(error.request);
        } else {
            callback('Error', error.message);
        }
    });
}
export default menuLanguage