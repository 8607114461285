import online from "./helpers/online";
import Vue from "vue";

export default {
  setDefaultNewUser(state) {
    state.new_user = state.defaultStates.states.new_user;
  },
  set_zones(state, zones) {
    state.zones = zones;
  },
  setLanguage(state, lang) {
    state.curLanguage = lang;
  },
  set_users_type(state, types) {
    state.usersTypes = types;
  },
  // Accounts mutations -----------------------------------
  set_app_users(state, users) {
    state.appUsers = users;
  },
  set_inactive_users(state, inactiveUsers) {
    state.inactiveUsers = inactiveUsers;
  },
  set_inactive_users_loading(state, value) {
    state.loadInactiveUsers = value;
  },
  setShowOnlyActiveUsers(state, isActive) {
    state.showOnlyActiveUsers = isActive;
  },
  setSearchUser(state, value) {
    // console.log("------------value", value);
    if (value) {
      state.searchUser = value.trim();
    }
  },
  setSearchAccountsByType(state, value) {
    state.accountTypeUser = value.trim();
  },
  setSearchAccountsByLocation(state, value) {
    state.accountUserLocation = value.trim();
  },
  // End accounts mutations -----------------------------------
  disconnectUser(state, data) {
    const { id, hasMessages } = data;
    let chatUsers = [];
    if (!hasMessages) {
      chatUsers = state.chatUsers.filter(
        (user) => user.id !== `chat_user_${id}`
      );
    } else {
      //if user disconnects but has unread messages for user
      chatUsers = state.chatUsers.map((user) => {
        if (user.user === id) {
          return (user = { ...user, state: "offline" });
        }
        return user;
      });
    }
    state.chatUsers = chatUsers;
  },
  set_chatUsers(state, users) {
    state.chatUsers = users;
  },
  set_vertical(state, menu) {
    state.vertical_menu = menu;
  },
  set_horizontal(state, menu) {
    state.horizontal = menu;
  },
  set_selected_studio(state, value) {
    state.selectedStudio = value;

    if (state.localStudios.length === 0) {
      state.localStudios = [value];
    }
  },
  // TODO(Madalin): replace with other mutation
  user_set(state, user) {
    console.log("setting user (user_set)", user);
    state.user = user;
  },
  set_user(state, user) {
    console.log("setting user", user);
    state.user = user;
  },
  studio_settings(state, settings) {
    state.settings = settings;
  },
  set_users(state, users) {
    state.users = users;
  },
  set_site_state(state, val) {
    if (!state.selected_user.states) {
      Vue.set(state.selected_user, "states", {});
    }

    state.selected_user.states[val.site] = val;
  },

  set_selected_user(state, user) {
    state.selected_user = user;
  },
  reset_selected_user(state) {
    state.selected_user = {
      ci: {
        serie: "",
        cnp: "",
      },
    };
    state.searchUser = "";
  },
  set_holidays(state, data) {
    state.holidays = data;
  },
  set_emailUsers(state, users) {
    console.log(users);
    state.emailUsers = users;
  },
  update_new_user(state, data) {
    //console.log(data.field, state.selected_user[data.field]);
    function setToValue(obj, value, path) {
      let i;
      path = path.split(".");
      for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];
      obj[path[i]] = value;
    }
    setToValue(state.new_user, data.value, data.field);
  },
  deleteBonusId(state, id) {
    state.settings.bonus.splice(id, 1);
  },
  update_user(state, data) {
    function setToValue(obj, value, path) {
      let i;
      path = path.split(".");
      let length = path.length - 1;

      for (i = 0; i < length; i++) {
        if (obj[path[i]] == undefined) {
          Vue.set(obj, path[i], i < length ? {} : "");
        }

        obj = obj[path[i]];
      }

      Vue.set(obj, path[i], value);
    }

    // console.log(data.value, data.field)
    setToValue(state.selected_user, data.value, data.field);
  },
  update_user_2fa(state, enabled2FA) {
    state.user.privilegii = enabled2FA;
  },
  // update_serie(state, serie) {
  //   state.selected_user.ci.serie = serie;
  // },
  set_user_studio(state, studio) {
    state.user_studio = studio;
  },
  set_widget_media(state, media) {
    state.widget_media = media;
  },
  set_media_tags(state, tags) {
    state.media_tags = tags;
  },
  set_media_test_tags(state, tags) {
    state.media_test_tags = tags;
  },

  set_email_note(state, data) {
    state.email_note = data.emails;
    state.emails_note_total = data.total;
  },
  delete_widget_media(state, id) {
    // console.log(id);
    let new_array = state.widget_media.filter(function (obj) {
      return obj.pic_id !== id;
    });
    state.widget_media = new_array;
  },
  update_widget_media_user(state, { user, id, assigned }) {
    // console.log("user, id, assigned", user, id, assigned);
    state.widget_media = state.widget_media.map((media) => {
      if (media.pic_id !== id) return media;

      let users = media.users || [];
      if (assigned) {
        users = [...users, user];
      } else {
        users = users.filter((entry) => entry !== user);
      }

      return { ...media, users };
    });
  },
  update_widget_media_assigned(state, { id, users }) {
    // console.log("user, id, assigned", user, id, assigned);
    state.widget_media = state.widget_media.map((media) => {
      if (media.pic_id !== id) return media;

      return { ...media, users };
    });
  },
  push_widget_media(state, media) {
    state.widget_media.push(media);
  },
  update_widget_media(state, data) {
    for (let i in state.widget_media) {
      if (state.widget_media[i].pic_id === data.id) {
        Vue.set(state.widget_media, i, {
          ...state.widget_media[i],
          ...{
            title: data.title,
            content: data.description,
            tags:
              "Tags: " + JSON.stringify(data.tags).replace(/(\[|\]|")/g, ""),
          },
        });

        // state.widget_media[i].title = data.title;
        // state.widget_media[i].content = data.description;
        // state.widget_media[i].tags =
        //   "Tags: " + JSON.stringify(data.tags).replace(/(\[|\]|")/g, "");
        break;
      }
    }
  },
  set_ussage(state, usage) {
    state.usage = usage;
  },
  set_app_sites(state, sites) {
    state.sites = sites;
  },
  set_selected_media(state, media) {
    state.selected_media = media;
  },
  set_isGallery(state, val) {
    state.isGallery = val;
  },
  setupId(state, id) {
    state.selected_user.conturi[id] = {
      activ: null,
      user: "",
      pass: "",
    };
  },
  set_selected_pictures(state, data) {
    let user =
      window.location.pathname === "/about-user"
        ? state.selected_user
        : state.user;
    if (data.action) {
      if (typeof user.media[data.type] === "undefined")
        user.media[data.type] = [];
      return (user.media[data.type] = [
        ...new Set([...user.media[data.type], ...data.val]),
      ]);
    }
    let filtered = user.media[data.type].filter((v) => !data.val.includes(v));
    user.media[data.type] = filtered;
  },
  // edit_widget_media(state, data){
  //   console.log(data.state)
  //   for(let i in state.widget_media){
  //     if(state.widget_media[i].pic_id === data.id){
  //       console.log(state.widget_media[i]);
  //       if(data.state){
  //         state.widget_media[i].buttons[0].dropdown[2].text = 'Remove From User';
  //         state.widget_media[i]['users'].push(state.selected_user.user);
  //       }
  //       else{
  //         console.log(state.widget_media[i])
  //         state.widget_media[i].buttons[0].dropdown[2].text = 'Assign To User';
  //         state.widget_media[i]['users'].splice(state.widget_media[i].users.indexOf(state.selected_user.user),1);
  //       }
  //       console.log('done edit_widget_media');
  //       break;
  //     }
  //   }
  // },
  changeChatStatus(state, value) {
    state.isChat = value;
  },
  changeChatSound(state, value) {
    state.chatSound = value;
  },
  updateAFK_State(state, data) {
    state.chatUsers.forEach((v) => {
      // console.log(v, v.user === data.user)
      if (v.user === data.user) {
        let state = data.state ? "away" : "online";
        // console.log(v.user, data.user, state)
        v.state = state;
      }
    });
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  muteAction(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        if (!data.state) v.muted.push(data.user);
        else v.muted.splice(v.muted.indexOf(data.user), 1);
      }
    });
  },
  viewEmail(state, mail) {
    state.mail = mail;
  },
  changeGroupImages(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        v.imgs = data.imgs;
      }
    });
  },
  setChatUnseenMessages(state, { from, count }) {
    state.chatUnseenMessages = { ...state.chatUnseenMessages, [from]: count };
  },
  incChatUnseenMessages(state, { from }) {
    const count = state.chatUnseenMessages[from] || 0;
    state.chatUnseenMessages = {
      ...state.chatUnseenMessages,
      [from]: count + 1,
    };
  },
  joinRequest(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        if (!v.users.includes(data.user)) {
          v.users.push(data.user);
          //remove the user from requests
          const index = v.request?.indexOf(data.user);
          if (index > -1) {
            v.request.splice(index, 1);
          }
        }
      }
    });
  },
  leaveRequest(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        let index = v.users.indexOf(data.user);
        v.users.splice(index, 1);
      }
    });
  },
  cancelRequest(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        const index = v.users.indexOf(data.user);
        v.request.splice(index, 1);
      }
    });
  },
  pendingRequest(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        if (!v.request.includes(data.user)) {
          v.request.push(data.user);
        }
      }
    });
  },
  deleteGroupRequest(state, id) {
    state.groups = state.groups.filter((group) => group._id !== id);
  },
  banAction(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        if (!data.state) v.banned.push(data.user);
        else v.banned.splice(v.banned.indexOf(data.user), 1);
      }
    });
  },
  addGroupUser(state, data) {
    state.groups.map((v) => {
      if (v._id === data.id) {
        // console.log(v);
        if (data.action) {
          // if (v.new == undefined) v.new = 1;
          // else v.new++;
          this.commit("incChatUnseenMessages", { from: v._id });
          v.users.push(data.user);
        }
        // console.log(v)
      }
    });
  },
  updateChatState(state, data) {
    if (data.val == undefined) return state.chatUsers.push(data[0]);
    let users = state.chatUsers.filter((v) => {
      return v.id.replace("chat_user_", "") != data.user;
    });
    state.chatUsers = users;
  },
  updateChatSessionState(state, { user, session }) {
    let chatUser = state.chatUsers.find((v) => v.user === user);
    if (chatUser) {
      chatUser["session"] = session;
    }
  },
  updateChatSetts(state, data) {
    if (state.user.chat_setts == undefined) state.user.chat_setts = {};
    if (state.user.chat_setts[data.studio] == undefined)
      state.user.chat_setts[data.studio] = {};
    state.user.chat_setts[data.studio][data.type] = data.val;
  },
  updateChatSound(state, { chatSound }) {
    state.user.chatSound = chatSound;
  },
  clearStates(state) {
    let s = state.defaultStates.states;
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setModelSettlment(state, value) {
    state.modelSettlment = value;
  },
  setIncome(state, list) {
    state.income = list;
  },
  setOnline(state, list) {
    state.online = list;
  },
  setShiftView(state, value) {
    state.toggleViews.shift = value;
  },
  setStatisticsView(state, value) {
    state.toggleViews.statistics = value;
  },
  setLocalStudios(state, list) {
    state.localStudios = list;
  },
  // store
  setStoreFiles(state, files) {
    state.storeFiles = files;
  },
  setScheduleResponse(state, response) {
    state.scheduleResponse = response;
  },
};
