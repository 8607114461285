const SECTION_KEY = "accounts/userSection";

const isManager = (user) => user.tipCont === "Manager";
const isTrainer = (user) =>
  user.tipCont === "Trainer" || user.tipCont === "Tehnic";
const isModel = (user) => user.tipCont === "Model";

// Accounts from **appUsers** have the **type** key **instead**
// of the **tipCont** key
const isManagerType = (user) => user.type === "Manager";
const isTrainerType = (user) =>
  user.type === "Trainer" || user.type === "Tehnic";
const isModelType = (user) => user.type === "Model";

const isSameUser = (a, b) => a.user === b.user;

const isAuthorized = (currentUser, viewedUser, section) => {
  switch (section) {
    case "payments":
      return true;
    case "user-tasks":
      return true;
    case "activity":
      return true;
    case "info":
      return (
        isSameUser(viewedUser, currentUser) ||
        (isModel(viewedUser) && isManager(currentUser))
      );
    case "training":
      return (
        (isModel(viewedUser) ||
          isTrainer(viewedUser) ||
          isManager(viewedUser)) &&
        (isSameUser(viewedUser, currentUser) ||
          isManager(currentUser) ||
          isTrainer(currentUser))
      );
    case "email":
      return (
        isSameUser(viewedUser, currentUser) ||
        (isModel(viewedUser) &&
          (isManager(currentUser) || isTrainer(currentUser)))
      );
    case "accounts":
      return isModel(viewedUser);
    case "password":
      return true;
    case "boost":
      return isModel(viewedUser) && currentUser.super;
    case "media":
      return true;
    case "logs":
      return isManager(currentUser);
    case "model-assignment":
      return isTrainer(viewedUser) && isManager(currentUser);
    case "holidays":
      return true;
    case "schedule":
      return currentUser.tipCont === "Manager";
    // overview ||
    default:
      return true;
  }
};

//permission, resourceType, resource, user, extra
const getDefaultState = () => {
  return {
    section: "overview",
    lookingAtOwnProfile: false,
    currentRoute: "",
    accountPermissions: [],
  };
};

const permissions = {
  shop: {
    manage(currentUser, resource, context = {}) {
      return isManager(currentUser) || currentUser.super;
    },
  },
  schedule: {
    manage(currentUser, resource, context = {}) {
      return (
        isManager(currentUser) ||
        currentUser.super ||
        context.accountPermissions.some(
          ({ permission, resourceType, resource, user, extra }) =>
            permission === "manage" &&
            resourceType === "schedule" &&
            user === currentUser.user
        )
      );
    },
    view(currentUser, resource, context = {}) {
      if (this.manage(currentUser, resource, (context = {}))) {
        return true;
      }
      return context.accountPermissions.some(
        ({ permission, resourceType, resource, user, extra }) =>
          permission === "view" &&
          resourceType === "schedule" &&
          user === currentUser.user
      );
    },
    // edit(currentUser, resource, context = {}) {},
  },
};

export default {
  namespaced: true,
  // data / ref
  state: () => getDefaultState(),
  // computed
  getters: {
    currentUser(state, getters, rootState) {
      return rootState.user;
    },
    viewedUser(state, getters, rootState) {
      return rootState.selected_user || {};
    },
    isAuthorized(state, getters) {
      return (section) =>
        isAuthorized(getters.currentUser, getters.viewedUser, section);
    },
    isManager(state, getters) {
      return isManager(getters.currentUser);
    },
    isModel(state, getters) {
      return isModel(getters.currentUser);
    },
    isTrainer(state, getters) {
      return isTrainer(getters.currentUser);
    },
    isSelectedUserModel(state, getters) {
      return isModel(getters.viewedUser);
    },
    isSelectedUserManager(state, getters) {
      return isManager(getters.viewedUser);
    },
    hasPermission(state, getters) {
      return (permission, resourceType, resource) => {
        if (
          !permissions[resourceType] ||
          typeof permissions[resourceType][permission] !== "function"
        ) {
          throw new Error(`No permission for ${permission} ${resourceType}`);
        }
        return permissions[resourceType][permission](
          getters.currentUser,
          resource,
          { accountPermissions: state.accountPermissions }
        );
      };
    },
    allManagers(_state, _getters, rootState) {
      const studio = rootState.selectedStudio;

      return rootState.appUsers.filter(
        (user) => isManagerType(user) && user.studio === studio
      );
    },
    allTrainers(_state, _getters, rootState) {
      const studio = rootState.selectedStudio;

      return rootState.appUsers.filter(
        (user) => isTrainerType(user) && user.studio === studio
      );
    },
    allModels(_state, _getters, rootState) {
      const studio = rootState.selectedStudio;

      return rootState.appUsers.filter(
        (user) => isModelType(user) && user.studio === studio
      );
    },
  },
  // methods - sync
  mutations: {
    changeSection(state, newSection) {
      state.section = newSection;
    },
    changeLookingAtOwnProfile(state, isLooking) {
      state.lookingAtOwnProfile = isLooking;
    },
    resetAccount(state) {
      Object.assign(state, getDefaultState());
      localStorage.removeItem(SECTION_KEY);
    },
    setAccountPermissions(state, value) {
      state.accountPermissions = value;
    },
  },
  // methods - async
  actions: {
    async getAccountPermissions({ commit, rootState }) {
      try {
        const { data } = await axios.get(
          `${rootState.appUrl}getAccountPermissions`
        );
        commit("setAccountPermissions", data);
      } catch (error) {
        console.log(error);
      }
    },
    getSectionFromStorage({ commit, getters }) {
      const section = localStorage.getItem(SECTION_KEY);
      if (!section) return;
      if (getters.isAuthorized(section)) {
        commit("changeSection", section);
      } else {
        commit("changeSection", "overview");
      }
    },

    setSection({ commit }, section) {
      localStorage.setItem(SECTION_KEY, section);
      commit("changeSection", section);
    },
  },
};
