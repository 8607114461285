export default {
  test_path: () => {
    return window.location.pathname !== "/";
  },
  get_user: (state) => {
    //console.log('this.$store.getters.get_user', state.user)
    return state.user;
  },
  // userKey: (state) => state.user?.user || '',
  currentUser: (state) => state.user?.user || "",
  get_holidays: (state) => {
    return state.holidays;
  },
  getStudioSettings: (state) => {
    return state.settings;
  },
  defaultCurrency: (state, getters) =>
    getters.getStudioSettings.currency || "usd",
  getAccountsByType: (state) => (studio, accountType) => {
    return state.appUsers.filter(
      (user) => user.studio === studio && user.type === accountType
    );
  },
  isShiftView: (state) => state.toggleViews.shift,
  isStatisticsView: (state) => state.toggleViews.statistics,
  checkVal: (state) => {
    let new_configs = state.selected_user.new_configs;
    let defaults = { mfc: {}, stripchat: {}, chaturbate: {}, bonga: {} };
    function configs() {
      if (new_configs == undefined) {
        new_configs = defaults;
      }
      for (let c in new_configs) {
        for (let i = 1; i < 7; ++i) {
          if (new_configs[c] == undefined) new_configs[c] = {};
          if (new_configs[c][i] == undefined) {
            new_configs[c][i] = {
              count: null,
              enabled: false,
            };
          }
        }
      }
      for (let c in defaults) {
        for (let i = 1; i < 7; ++i) {
          if (new_configs[c] == undefined) new_configs[c] = {};
          if (new_configs[c][i] == undefined) {
            new_configs[c][i] = {
              count: null,
              enabled: false,
            };
          }
        }
      }
      // console.log('---------------------->>>>>>>>>>>>>>>>>',new_configs);
      return new_configs;
    }
    return configs();
  },
  allShifts(state) {
    const shifts = Object.keys(state.settings?.ture?.interval || {});
    shifts.sort();
    return shifts;
  },
  numberOfShifts(state) {
    return state.settings?.ture?.nr || 0;
  },
  labelsForLocale(state) {
    return state.labels[state.locale] || state.labels.en;
  },
  helpTextForLocale(state) {
    return state.helpText[state.locale] || state.helpText.en;
  },
  anonimImg(state) {
    // return `${state.appUrl}img/anonim.png`;
    return `${state.appUrl}img/anon.385ed079.png`;
  },
  // Accounts getters --------------------------------------
  usersByActiveState(state) {
    // console.log("state", state);
    if (state.showOnlyActiveUsers) {
      return state.appUsers;
    } else {
      // Inactive users may not be loaded
      return state.inactiveUsers || [];
    }
  },
  filteredUsers(state, getters) {
    // Filter by active or inactive users
    let users = getters.usersByActiveState;
    // console.log("users,", users);
    // Filter by search field
    const searchField = state.searchUser.toLowerCase();
    const searchAccountType = state.accountTypeUser.toLowerCase();
    const searchLocation = state.accountUserLocation.toLowerCase();
    // if (searchField) {
    //   users = users.filter(
    //     (v) =>
    //       v.user.toLowerCase().includes(searchField) ||
    //       v.name.toLowerCase().includes(searchField) ||
    //       v.surname.toLowerCase().includes(searchField)
    //   );
    // }

    // return users;
    console.log("searchField", searchField);
    if (searchField || searchAccountType || searchLocation) {
      users = users.filter((user) => {
        if (user) {
          const matchesSearchField =
            !searchField ||
            user.user.toLowerCase().includes(searchField) ||
            user.name.toLowerCase().includes(searchField) ||
            user.surname.toLowerCase().includes(searchField);

          let matchesAccountType;
          let matchesLocation;

          if (searchAccountType === "all") {
            matchesAccountType = true;
          } else if (user.type) {
            matchesAccountType =
              !searchAccountType ||
              user.type.toLowerCase() === searchAccountType;
          } else {
            matchesAccountType = false;
          }

          if (searchLocation === "all") {
            matchesLocation = true;
          } else if (user.studio) {
            matchesLocation =
              !searchLocation || user.studio.toLowerCase() === searchLocation;
          } else {
            matchesLocation = false;
          }
          return matchesSearchField && matchesAccountType && matchesLocation;
        }
      });
    }
    // console.log("((((((((((((((((((filteredUsers((((", users);
    return users;
  },
  userCount(state, getters) {
    return getters.filteredUsers.length;
  },
  // End accounts getters --------------------------------------

  // store
  getStoreFiles: (state) => state.storeFiles,
  getScheduleResponse: (state) => state.scheduleResponse,
};
