import axios from "axios";
import { handleAxiosError } from '@/helpers/errors'
import { newTask } from './taskModel';

export default {
    namespaced: true,
    state: () => ({
        loadingProjects: true,
        projects: {},
        selectedProject: null,
    }),
    getters: {
        projectList: (state) => {
            let projects = [];
            for (let projectKey of Object.keys(state.projects)) {
                let project = state.projects[projectKey];
                let name = project.settings?.name || projectKey;
                projects.push({ ...project, name, key: projectKey });
            }

            return projects;
        },
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
        },
        setLoadingProjects(state, loading) {
            state.loadingProjects = loading;
        },
        setSelectedProject(state, project) {
            state.selectedProject = project;
        },
        setTasksForProject(state, { tasks, projectKey }) {
            // Filter out dummy tasks (projects and sections are saved as dummy tasks
            // with parent == project name, child == section name)
            tasks = tasks.filter((task) => !task.dummy);

            let project = state.projects[projectKey];
            if (!project) {
                console.error('missing project', projectKey);
                return
            }

            let indexedSections = Object.create(null);
            let indexedTasks = Object.create(null);


            for (let section of project.sections) {
                section.tasks = [];
                indexedSections[section.child] = section;
            }

            for (let task of tasks) {
                let section = indexedSections[task.child];
                if (!section) {
                    console.error('missing section', task.child, task._id);
                    continue
                }

                indexedTasks[task._id] = task;

                // Only place top level tasks in sections
                if (task.support.length === 0) {
                    section.tasks.push(task);
                }
            }

            for (let task of tasks) {
                task.subtasks = (task.dependencies || []).map((id) => indexedTasks[id]).filter((task) => !!task);
            }
        },
    },
    actions: {
        async fetchProjectsWithSections({ rootState, state, commit, dispatch }) {
            commit('setLoadingProjects', true);
            KTApp.blockPage();

            try {
                const result = await axios.post(`${rootState.appUrl}getTasksCategories`);
                if (result.status !== 200) {
                    throw new Error(`Unexpected status fetching projects: ${result.status}`);
                }

                let projects = Object.create(null);

                for (let entry of result.data) {
                    let project = projects[entry.parent];
                    if (!project) {
                        project = { sections: [] };
                        projects[entry.parent] = project;
                    }

                    // An entry is either a project or a section inside a project.
                    // Sections have the `child` property set to the section's name.
                    if (entry.child === '') {
                        project.settings = entry.settings;
                        project.creator = entry.creator;
                        project.uid = entry._id;

                        // entry.parent is the original name of the project, set as parent to other sections and tasks.
                        // entry.settings.name is the updated name of a project, edited by the user afterwards
                        project.name = entry.settings?.name || entry.parent;

                        // **parent** is the project key used to identify which sections and tasks belong to it.
                        // every section in this project will have parent === parent key of project, same for tasks
                        project.parent = entry.parent;
                    } else {
                        project.sections.push({ ...entry, name: entry.child, tasks: [] });
                    }
                }

                commit('setProjects', projects);

                // Preselect first project if none is selected
                if (!state.selectedProject && result.data.length > 0) {
                    const firstProject = result.data[0].parent;
                    await dispatch('selectProject', firstProject);
                }
            } catch (error) {
                handleAxiosError(error);
            } finally {
                commit('setLoadingProjects', false);
                KTApp.unblockPage();
            }
        },

        async selectProject({ state, commit, dispatch }, projectKey) {
            let project = state.projects[projectKey];
            if (!project) {
                console.error('missing project', projectKey)
                return;
            }

            commit('setSelectedProject', project);
            await dispatch('fetchTasksForProject', {
                 // TODO(madalin): understand what index does
                index: 1,
                parent: projectKey,
                uid: project.uid,
            });
        },


        async createProject({ rootState, rootGetters, dispatch }, { name }) {
            const user = rootGetters.userKey;

            KTApp.blockPage();

            try {
                // Projects are saved as dummy task tasks with **parent** as their relational key
                const newProjectEntry = newTask({
                    uid: "",
                    parent: name,
                    creator: user,
                    from: user,
                    studio: rootState.selectedStudio.toLowerCase(),
                    dummy: true,
                });

                const result = await axios.post(`${rootState.appUrl}saveTask`, newProjectEntry);
                if (result.status !== 200 && result.status !== 201) {
                    throw new Error(`Unexpected status saving new project: ${result.status}`);
                }

                await dispatch('fetchProjectsWithSections');
                await dispatch('selectProject', newProjectEntry.parent);

                toastr.success("Project created successfully!");
            } catch (error) {
                handleAxiosError(error);
            } finally {
                KTApp.unblockPage();
            }
        },

        async deleteProject({ rootState, dispatch, state }, { uid }) {
            KTApp.blockPage();

            try {
                const deleteSelected = state.selectedProject?.uid === uid;
                const result = await axios.post(`${rootState.appUrl}deleteTaskProject`, { uid });
                if (result.status !== 200) {
                    toastr.error('Failed to delete project!');
                    throw new Error(`Unexpected status deleting project: ${result.status}`);
                }

                await dispatch('fetchProjectsWithSections');

                const firstProject = Object.keys(state.projects)[0];
                if (deleteSelected && firstProject) {
                    await dispatch('selectProject', firstProject);
                }

                toastr.success('Project deleted successfully!');
            } catch (error) {
                handleAxiosError(error);
            } finally {
                KTApp.unblockPage();
            }
        },

        async createSection({ rootState, rootGetters, state, dispatch }, { name }) {
            const user = rootGetters.userKey;
            KTApp.blockPage();

            try {
                const parent = state.selectedProject?.parent;
                if (!parent) {
                    throw new Error('parent project is not selected!');
                }

                // Sections are saved as dummy task tasks with **(parent, child)** as their relational key,
                // parent is their project, while child represents the section's name
                const newSectionEntry = newTask({
                    parent,
                    child: name,
                    creator: user,
                    from: user,
                    studio: rootState.selectedStudio.toLowerCase(),
                    dummy: true,
                });

                const result = await axios.post(`${rootState.appUrl}saveTask`, newSectionEntry);
                if (result.status !== 200 && result.status !== 201) {
                    throw new Error(`Unexpected status saving new section: ${result.status}`);
                }

                await dispatch('fetchProjectsWithSections');
                // Re-select parent project to get new section
                await dispatch('selectProject', newSectionEntry.parent);

                toastr.success('Section created successfully!');
            } catch (error) {
                handleAxiosError(error);
            } finally {
                KTApp.unblockPage();
            }
        },

        async fetchTasksForProject({ rootState, commit } , { uid, parent, index }) {
            KTApp.blockPage();

            try {
                const result = await axios.post(`${rootState.appUrl}getTasks`, { parent, uid, index });
                if (result.status !== 200) {
                    throw new Error(`Unexpected status fetching tasks for project ${parent}: ${result.status}`);
                }

                commit('setTasksForProject', { tasks: result.data, projectKey: parent });
            } catch (error) {
                handleAxiosError(error);
            } finally {
                KTApp.unblockPage();
            }
        },

        // async moveTask({}, { section,  }) {

        // }
    },
};
