// Helper functions for dealing with tasks

export const newTask = (data) => {
    return {
        uid: data.uid || '',
        _id: data._id || null,
        parent: data.parent || '', // || this.project,
        child: data.child || '', // ||  this is the task's section
        project: data.project || '', // this.currentProject
        dummy: data.dummy,
        dependencies: data.dependencies || [],
        title: data.title || '',
        body: data.body || '',
        attachments: data.attachments || [],
        priority: data.priority || 0,
        progress: 1,
        date: data.date || moment().unix(),
        start: data.start || 0,
        end: data.end || 0,
        shown: true,
        comments: data.comments || [],
        logs: data.logs || [],
        creator: data.creator, // || add_user,
        notes: data.notes || [],
        from: data.from, // || add_user,
        to: data.to || '',
        support: data.support || [],
        studio: data.studio, // || studioChange.toLowerCase(),
    }
}


// POST /saveTask     for "Wolves"
// {
//     "uid": "64b6496bc4c945964fb24143",
//     "_id": null,
//     "project": "",
//     "dependencies": [],
//     "title": "",
//     "body": "",
//     "attachments": [],
//     "priority": 0,
//     "date": 1689840621,
//     "start": 0,
//     "end": 0,
//     "shown": true,
//     "comments": [],
//     "logs": [],
//     "creator": "andyc",
//     "notes": [],
//     "from": "andyc",
//     "to": "",
//     "support": [],
//     "studio": "teststudio",
//     "parent": "Wolves",
//     "child": "",
//     "progress": 1,
//     "dummy": true
// }