import { handleAxiosError } from "@/helpers/errors";
import axios from "axios";

const SESSION_STATE = {
  LIBER: 0, //tura OFF
  LUCRU: 1, //tura ON / pauza OFF
  PAUZA: 2, //tura ON / pauza ON
};

const sessionStateMap = {
  [SESSION_STATE.LIBER]: "not-started",
  [SESSION_STATE.LUCRU]: "work",
  [SESSION_STATE.PAUZA]: "break",
};

const SESSION_ACTION = {
  CHECK: 0, //verifica stare
  SSHIFT: 1, //start tura
  XSHIFT: 2, //stop tura
  SPAUSE: 3, //start pauza
  XPAUSE: 4, //stop pauza
};

const getDefaultState = () => {
  return {
    session: {
      state: 0,
      times: null,
    },
    startShiftTime: 0,
    totalWorkTime: 0,
  };
};

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    sessionState(state) {
      return sessionStateMap[state.session.state];
    },
  },
  mutations: {
    setSession(state, newSession) {
      state.session = newSession;
      if (state.session.state === 1) {
        // set current time as a start for new shift
        state.startShiftTime = moment().unix();
      }
      // console.log("  state.session", state.session);
      if (newSession && newSession.times && newSession.times.lucru) {
        state.totalWorkTime = Math.max(0, newSession.times.lucru);
      }
    },
    tickSessionTimes(state) {
      const { session } = state;
      // console.log("session", session);
      if (!session || !session.times) {
        return false;
      }
      // this.pause = session.times.pauza;
      // this.type = "Total work";
      // console.log('time');
      switch (session.state) {
        case SESSION_STATE.LIBER:
          {
            // this.onShift = this.formatTime(session.times.lucru);
          }
          break;
        case SESSION_STATE.LUCRU: //actualizeaza timerul de lucru
          {
            // this.type = 'Total work:';

            //if it is a new shift, calculate difference between current time
            //and startShiftTime which is set when the shift starts
            if (session.times.lucru === 0) {
              state.totalWorkTime = moment().unix() - state.startShiftTime;
            } else {
              state.totalWorkTime =
                moment().unix() - session.times.start - session.times.pauza;
            }
          }
          break;
        case SESSION_STATE.PAUZA: //actualizeaza timerul de total pauza si pauza curenta
          {
            session.times.pauza++;
            session.times.last++;
          }
          break;
      }
    },
    resetShift(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    startShift({ dispatch }) {
      dispatch("updateSession", { action: SESSION_ACTION.SSHIFT });
    },
    stopShift({ dispatch }) {
      dispatch("updateSession", { action: SESSION_ACTION.XSHIFT });
    },
    pauseShift({ dispatch }) {
      dispatch("updateSession", { action: SESSION_ACTION.SPAUSE });
    },
    resumeShift({ dispatch }) {
      dispatch("updateSession", { action: SESSION_ACTION.XPAUSE });
    },

    getSession({ commit, rootState }) {
      return axios
        .post(rootState.appUrl + "session_state", {
          user_name: rootState.user.user,
          action: SESSION_ACTION.CHECK,
        })
        .then((res) => {
          console.log("getSession", res.data);
          commit("setSession", res.data);
        })
        .catch((error) => handleAxiosError(error));
    },
    updateSession({ state, rootState, commit }, { action }) {
      let data = {
        action,
        user_name: rootState.user.user,
      };

      KTApp.blockPage();
      axios
        .post(rootState.appUrl + "session_state", data)
        .then((res) => {
          console.log("res from session_state", res.data);

          KTApp.unblockPage();
          // this.onPause = 0;
          let data = res.data;

          commit("shift/setSession", res.data, { root: true });
          commit(
            "updateChatSessionState",
            {
              user: data.user_name,
              session: res.data.state,
            },
            { root: true }
          );

          if (this._vm.$root.socket) {
            this._vm.$root.socket.emit(
              "update_user_state",
              state.session.state,
              data.user_name,
              rootState.user.studioul
            );
          }
        })
        .catch((error) => {
          KTApp.unblockPage();
          handleAxiosError(error);
        });
    },
  },
};
